import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import useAxios from "../../hooks/useAxios";
import Input from "../Input";
import useFormData from "../../hooks/useFormData";
import "./index.css";
import { Button } from "@mui/material";
import Select from "../Select";
import useAsyncStorage from "../../hooks/useAsyncStorage";

let file = false;

export default function DocumentUploader({ id, placeholder, onResetNovedad }) {
  const master = useAsyncStorage("master");
  const { formData, onChange, setFormData } = useFormData();
  const axios = useAxios();
  const [reset, setReset] = useState(false);
  const [loading, setLoading] = useState(false);

  console.log("Line 20 formData", formData);

  const handleSelectChange = (selectedValue) => {
    console.log("Selected value:", selectedValue); // Muestra el valor seleccionado

    if (selectedValue) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        type_news_id: selectedValue, // Actualiza el estado correctamente
      }));
    } else {
      console.error("Error: No value selected");
    }
  };

  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    axios
      .postData(
        { ...formData, modulo_token: id, attachment: 100 },
        "api/v1/dashboard/comments"
      )
      .then((response) => {
        setTimeout(() => {
          setReset(false);
        }, 1500);

        setLoading(false);
        onResetNovedad();

        return;
      });
  };

  return (
    <Grid component={"form"} className="container-file" onSubmit={handleSubmit}>
      {loading && <Grid align="center">Subiendo la información</Grid>}
      {reset && <Grid align="center">Depurando el sistema...</Grid>}
      {!reset && (
        <Grid align={"center"}>
          <>
            <Grid sx={{ mb: 2 }}>
              {
                <Select
                  items={
                    master.data &&
                    master.data.type_news &&
                    master.data.type_news.length > 0
                      ? master.data.type_news
                      : []
                  }
                  // variant="standard"
                  sx={{ mb: { md: 1, xs: 0 }, width: "100%" }}
                  onChange={handleSelectChange}
                  fullWidth
                  name="type_news_id"
                  label="Tipo de novedad"
                  value={formData.type_news_id || ""}
                />
              }
            </Grid>
            <Grid
              style={{
                opacity: !formData.type_news_id ? 0.5 : 1, // Reducir la opacidad cuando está desactivado
                pointerEvents: !formData.type_news_id ? "none" : "auto", // Deshabilitar interacciones cuando está desactivado
              }}
            >
              <Input
                sx={{ mb: 2, width: "100%" }}
                required
                onChange={onChange}
                fullWidth
                name="valor"
                type="number"
                label="Valor"
              />
              <Input
                sx={{ mb: 2, width: "100%" }}
                onChange={onChange}
                fullWidth
                name="mensaje"
                type="text"
                label={placeholder || "Descripción"}
              />
              <Button disabled={loading} type="submit" variant="contained">
                Guardar Novedad
              </Button>
            </Grid>
          </>
        </Grid>
      )}
    </Grid>
  );
}
