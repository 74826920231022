import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import useAxios from "../../../hooks/useAxios";
import React, { useState } from "react";
import Select from "../../../components/Select";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";

const Basic = ({ data, trasportadores, getInit }) => {
  const axios = useAxios();
  const [inputs, setInputs] = useState({});
  const [transportador, setTranportador] = useState({});
  const storedData = localStorage.getItem("master");
  const [error, setError] = React.useState("");

  const onSubmit = (e) => {
    e.preventDefault();
    axios.putData(inputs, "api/v1/dashboard/services").then((response) => {
      if (response && response.data && response.data.id) {
        // Llama a getInit para actualizar la vista
        // getInit(true);
        window.location.reload();
      }
    });
  };

  React.useEffect(() => {
    if (data) {
      setInputs(data);
    }
  }, [data, setInputs]);

  React.useEffect(() => {
    if (trasportadores) {
      setTranportador(
        trasportadores.find(
          (search) => search.value === parseInt(inputs.transportador_id)
        )
      );
    }
  }, [inputs.transportador_id, trasportadores]);
  React.useEffect(() => {
    if (trasportadores) {
      setTranportador(
        trasportadores.find(
          (search) => search.value === parseInt(inputs.transportador_id)
        )
      );
    }
  }, [inputs.transportador_id, trasportadores]);

  const handleSelectChange = (value) => {
    setInputs((prevInputs) => ({
      ...prevInputs,
      transportador_id: value,
    }));
  };

  const getVehicleLabel = (id) => {
    try {
      const master = JSON.parse(storedData);

      function getVehicles(master) {
        return master.vehiculo.map((vehiculo) => ({
          label: vehiculo.label,
          value: vehiculo.value,
          images: vehiculo.images,
        }));
      }

      const vehicles = getVehicles(master);

      const vehicle = vehicles.find((item) => item.value === Number(id));

      return vehicle ? vehicle.label : "";
    } catch (err) {
      console.error("Error al obtener datos de almacenamiento", err);
      setError(err);
      console.log("Error al obtener datos de almacenamiento", error);
      return "";
    }
  };

  return (
    <form onSubmit={onSubmit}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={3}>
          <Select
            value={inputs.transportador_id}
            items={trasportadores || []}
            required
            onChange={handleSelectChange} // Solo pasa el valor
            size="small"
            fullWidth
            name="transportador_id"
            type="text"
            label="Nombre"
          />
        </Grid>
        <Grid item xs={12} md={9}>
          {transportador && transportador.all && (
            <Card elevation={3}>
              <CardContent>
                <Grid container spacing={2}>
                  {/* User Data Column */}
                  <Grid item xs={12} md={6}>
                    <Typography variant="h6" gutterBottom>
                      Datos del Transportador
                    </Typography>
                    <Divider sx={{ mb: 2 }} />
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <Typography variant="subtitle2">
                          Nombre Transportador
                        </Typography>
                        <Typography variant="body2">
                          {transportador.all.name}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="subtitle2">Documento</Typography>
                        <Typography variant="body2">
                          {transportador.all.documento_representante_legal}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="subtitle2">
                          Correo Electrónico
                        </Typography>
                        <Typography variant="body2">
                          {transportador.all.email}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="subtitle2">
                          Número de Teléfono
                        </Typography>
                        <Typography variant="body2">
                          {transportador.all.celular.trim().substring(0, 2) ===
                          "57"
                            ? transportador.all.celular.trim().substring(2)
                            : transportador.all.celular}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* Vehicle Data Column */}
                  <Grid item xs={12} md={6}>
                    <Typography variant="h6" gutterBottom>
                      Datos del Vehículo
                    </Typography>
                    <Divider sx={{ mb: 2 }} />
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <Typography variant="subtitle2">Marca</Typography>
                        <Typography variant="body2">
                          {transportador.all.vehiculo_marca}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="subtitle2">Modelo</Typography>
                        <Typography variant="body2">
                          {transportador.all.vehiculo_modelo}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="subtitle2">Placa</Typography>
                        <Typography variant="body2">
                          {transportador.all.vehiculo_placa}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="subtitle2">
                          Seguro Vencimiento
                        </Typography>
                        <Typography variant="body2">
                          {transportador.all.vehiculo_seguro_vencimiento}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="subtitle2">
                          Tecnomecánica
                        </Typography>
                        <Typography variant="body2">
                          {transportador.all.vehiculo_tecnomecanica}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="subtitle2">
                          Tecnomecánica Vencimiento
                        </Typography>
                        <Typography variant="body2">
                          {transportador.all.vehiculo_tecnomecanica_vencimiento}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="subtitle2">
                          Tipo de Vehículo
                        </Typography>
                        <Typography variant="body2">
                          {getVehicleLabel(
                            transportador.all.vehiculo_tipo_vehiculo
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          )}
        </Grid>
      </Grid>
      <Grid container justifyContent="flex-end">
        <Grid item xs={12} md={6} align="right" sx={{ mt: 2 }}>
          <Button variant="contained" color="primary" type="submit" fullWidth>
            Guardar
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default Basic;
