import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Box from "@mui/material/Box"; // Para usar Box como espaciador

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    fontWeight: "bold",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function TwoColumnLayout({ data }) {
  return (
    <Box>
      <TableContainer component={Paper} sx={{ marginBottom: 4 }}>
        <Table
          size="small"
          sx={{ minWidth: 700 }}
          aria-label="customized table"
        >
          <TableHead>
            <TableRow>
              <StyledTableCell align="center" colSpan={2}>
                Información general vehículo
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(!data || !Array.isArray(data)) && (
              <StyledTableRow>
                <StyledTableCell align="center" colSpan={2}>
                  No hay registros disponibles
                </StyledTableCell>
              </StyledTableRow>
            )}
            {data &&
              Array.isArray(data) &&
              data.map((row, key) => (
                <React.Fragment key={key}>
                  <StyledTableRow>
                    <StyledTableCell>
                      <strong>Placa:</strong>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.plate}
                    </StyledTableCell>
                  </StyledTableRow>

                  <StyledTableRow>
                    <StyledTableCell>
                      <strong>Color:</strong>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.color}
                    </StyledTableCell>
                  </StyledTableRow>

                  <StyledTableRow>
                    <StyledTableCell>
                      <strong>Marca:</strong>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.brand}
                    </StyledTableCell>
                  </StyledTableRow>

                  <StyledTableRow>
                    <StyledTableCell>
                      <strong>Modelo:</strong>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.model}
                    </StyledTableCell>
                  </StyledTableRow>

                  <StyledTableRow>
                    <StyledTableCell>
                      <strong>Cilindraje:</strong>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.engine}
                    </StyledTableCell>
                  </StyledTableRow>

                  <StyledTableRow>
                    <StyledTableCell>
                      <strong>Capacidad de carga:</strong>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.carrying_capacity}
                    </StyledTableCell>
                  </StyledTableRow>

                  <StyledTableRow>
                    <StyledTableCell>
                      <strong>Tipo de carrocería:</strong>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.chassis_type}
                    </StyledTableCell>
                  </StyledTableRow>

                  <StyledTableRow>
                    <StyledTableCell>
                      <strong>No. Licencia de transito:</strong>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.traffic_license}
                    </StyledTableCell>
                  </StyledTableRow>

                  <StyledTableRow>
                    <StyledTableCell>
                      <strong>Organismo de transito:</strong>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.traffic_agency}
                    </StyledTableCell>
                  </StyledTableRow>

                  <StyledTableRow>
                    <StyledTableCell>
                      <strong>Fecha de matrícula:</strong>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.enrolment_date}
                    </StyledTableCell>
                  </StyledTableRow>

                  <StyledTableRow>
                    <StyledTableCell>
                      <strong>Tipo de servicio:</strong>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.service_type}
                    </StyledTableCell>
                  </StyledTableRow>

                  <StyledTableRow>
                    <StyledTableCell>
                      <strong>Clasificación:</strong>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.clasification}
                    </StyledTableCell>
                  </StyledTableRow>

                  <StyledTableRow>
                    <StyledTableCell>
                      <strong>Clase de vehículo:</strong>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.vehicle_type}
                    </StyledTableCell>
                  </StyledTableRow>

                  <StyledTableRow>
                    <StyledTableCell>
                      <strong>Número de ejes:</strong>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.axles_number}
                    </StyledTableCell>
                  </StyledTableRow>

                  <StyledTableRow>
                    <StyledTableCell>
                      <strong>Tarjeta de servicio:</strong>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.service_card}
                    </StyledTableCell>
                  </StyledTableRow>
                </React.Fragment>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {/* Tablas de SOAT y RTM fuera del contenedor del vehículo */}
      {data && data[0]?.soat_details && (
        <SoatDetailsTable soatDetails={data[0].soat_details} />
      )}
      {data && data[0]?.rtm_details && (
        <RtmDetailsTable rtmDetails={data[0].rtm_details} />
      )}
    </Box>
  );
}

export function SoatDetailsTable({ soatDetails }) {
  return (
    <TableContainer component={Paper} sx={{ marginBottom: 4 }}>
      <Table
        size="small"
        sx={{ minWidth: 700 }}
        aria-label="soat details table"
      >
        <TableHead>
          <TableRow>
            <StyledTableCell align="center" colSpan={6}>
              Detalles de SOAT
            </StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell>Número de póliza</StyledTableCell>
            <StyledTableCell>Fecha de expedición</StyledTableCell>
            <StyledTableCell>Inicio de vigencia</StyledTableCell>
            <StyledTableCell>Fin de vigencia</StyledTableCell>
            <StyledTableCell>Estado de la póliza</StyledTableCell>
            <StyledTableCell>Entidad SOAT</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {soatDetails && soatDetails.length > 0 ? (
            soatDetails.map((detail) => (
              <StyledTableRow key={detail.id}>
                <StyledTableCell>{detail.policy_number}</StyledTableCell>
                <StyledTableCell>{detail.expedition_date}</StyledTableCell>
                <StyledTableCell>{detail.start_date_vigency}</StyledTableCell>
                <StyledTableCell>{detail.end_date_vigency}</StyledTableCell>
                <StyledTableCell>{detail.policy_status}</StyledTableCell>
                <StyledTableCell>{detail.soat_entity}</StyledTableCell>
              </StyledTableRow>
            ))
          ) : (
            <StyledTableRow>
              <StyledTableCell align="center" colSpan={6}>
                No hay detalles de SOAT disponibles
              </StyledTableCell>
            </StyledTableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function RtmDetailsTable({ rtmDetails }) {
  return (
    <TableContainer component={Paper} sx={{ marginBottom: 4 }}>
      <Table size="small" sx={{ minWidth: 700 }} aria-label="rtm details table">
        <TableHead>
          <TableRow>
            <StyledTableCell align="center" colSpan={6}>
              Detalles de Revisión Técnico-Mecánica
            </StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell>Número de certificado</StyledTableCell>
            <StyledTableCell>Tipo de revisión</StyledTableCell>
            <StyledTableCell>Fecha de expedición</StyledTableCell>
            <StyledTableCell>Fecha de vigencia</StyledTableCell>
            <StyledTableCell>Estado de vigencia</StyledTableCell>
            <StyledTableCell>Entidad RTM</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rtmDetails && rtmDetails.length > 0 ? (
            rtmDetails.map((detail) => (
              <StyledTableRow key={detail.id}>
                <StyledTableCell>{detail.certificate_number}</StyledTableCell>
                <StyledTableCell>{detail.review_type}</StyledTableCell>
                <StyledTableCell>{detail.expedition_date}</StyledTableCell>
                <StyledTableCell>{detail.vigency_date}</StyledTableCell>
                <StyledTableCell>{detail.vigency_status}</StyledTableCell>
                <StyledTableCell>{detail.rtm_entity}</StyledTableCell>
              </StyledTableRow>
            ))
          ) : (
            <StyledTableRow>
              <StyledTableCell align="center" colSpan={6}>
                No hay detalles de RTM disponibles
              </StyledTableCell>
            </StyledTableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
