import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { formatCurrency } from "../../helpers/functions";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function CustomizedTables({ data }) {
  console.log("line 33", data);
  return (
    <TableContainer component={Paper}>
      <Table size="small" sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell align="center">Tipo</StyledTableCell>
            <StyledTableCell align="center">Novedad</StyledTableCell>
            <StyledTableCell align="center">Fecha</StyledTableCell>
            <StyledTableCell align="center">Monto</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody sx={{ maxHeight: 100, overflowY: "scroll" }}>
          {(!data || !Array.isArray(data)) && (
            <StyledTableRow>
              <StyledTableCell align="center" colSpan={4}>
                No Hay documentos disponibles
              </StyledTableCell>
            </StyledTableRow>
          )}
          {data &&
            Array.isArray(data) &&
            data.map((row, key) => (
              <StyledTableRow key={key}>
                <StyledTableCell align="left">
                  {row.type_news && row.type_news.label}
                </StyledTableCell>
                <StyledTableCell align="left">{row.mensaje}</StyledTableCell>
                <StyledTableCell align="center">{row.fecha}</StyledTableCell>
                <StyledTableCell align="right">
                  {formatCurrency(row.valor || 0)}
                </StyledTableCell>
              </StyledTableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
